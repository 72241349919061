import * as React from 'react';
import './index.css';
import {
	widget,
	ChartingLibraryWidgetOptions,
	LanguageCode,
	IChartingLibraryWidget,
	ResolutionString,
} from '../../charting_library';

export interface ChartContainerProps {
	symbol: ChartingLibraryWidgetOptions['symbol'];
	interval: ChartingLibraryWidgetOptions['interval'];

	// BEWARE: no trailing slash is expected in feed URL
	datafeedUrl: string;
	libraryPath: ChartingLibraryWidgetOptions['library_path'];
	chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
	chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
	clientId: ChartingLibraryWidgetOptions['client_id'];
	userId: ChartingLibraryWidgetOptions['user_id'];
	fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
	autosize: ChartingLibraryWidgetOptions['autosize'];
	studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
	container: ChartingLibraryWidgetOptions['container'];
}

export interface ChartContainerState {
}

function getLanguageFromURL(): LanguageCode | null {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
}

function getSymbolFromURL(): string | null {
	const regex = new RegExp('[\\?&]symbol=([^&#]*)');
	const results = regex.exec(location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function getOrdersFromURL(): string | null {
	const regex = new RegExp('[\\?&]orders=([^&#]*)');
	const results = regex.exec(location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export class TVChartContainer extends React.PureComponent<Partial<ChartContainerProps>, ChartContainerState> {
	public static defaultProps: Omit<ChartContainerProps, 'container'> = {
		symbol: 'BTCUSDT',
		interval: 'D' as ResolutionString,
		datafeedUrl: 'https://api.giahungtrieu.net',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: false,
		studiesOverrides: {},
	};

	private tvWidget: IChartingLibraryWidget | null = null;
	private ref: React.RefObject<HTMLDivElement> = React.createRef();

	public componentDidMount(): void {
		if (!this.ref.current) {
			return;
		}

		const widgetOptions: ChartingLibraryWidgetOptions = {
			// symbol: this.props.symbol as string,
			symbol: getSymbolFromURL() || 'BTCUSDT',
			// BEWARE: no trailing slash is expected in feed URL
			// tslint:disable-next-line:no-any
			datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(this.props.datafeedUrl),
			interval: this.props.interval as ChartingLibraryWidgetOptions['interval'],
			container: this.ref.current,
			library_path: this.props.libraryPath as string,

			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: this.props.fullscreen,
			autosize: this.props.autosize,
			studies_overrides: this.props.studiesOverrides,
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
						title: 'Notification',
						body: 'TradingView Charting Library API works correctly',
						callback: () => {
							console.log('Noticed!');
						},
					}));
				button.innerHTML = 'Check API';
			});
			var orders = getOrdersFromURL();
			if (orders != null) {
				const splitted = orders.split(';');
				splitted.forEach(function (value: string) {
					if (value !== null || value !== '') {
						const o = value.split('-');
						if (o[0] === 'OPL') {
							var opl = tvWidget.chart().createOrderLine().setText('Open Long ' + o[1]).setLineLength(3).setLineColor('rgba(14,202,128,1)').setLineStyle(2);
							opl.setPrice(Number(o[1]));
						}
						if (o[0] === 'OPS') {
							var ops = tvWidget.chart().createOrderLine().setText('Open Short ' + o[1]).setLineLength(3).setLineColor('rgba(246,70,92,1)').setLineStyle(2);
							ops.setPrice(Number(o[1]));
						}
						if (o[0] === 'TPL') {
							var tpl = tvWidget.chart().createOrderLine().setText('Take Profit Long ' + o[2]).setLineLength(3).setLineColor('rgba(14,202,128,1)').setLineStyle(2);
							tpl.setPrice(Number(o[2]));
						}
						if (o[0] === 'SLL') {
							var sll = tvWidget.chart().createOrderLine().setText('Stop Loss Long ' + o[3]).setLineLength(3).setLineColor('rgba(14,202,128,1)').setLineStyle(2);
							sll.setPrice(Number(o[3]));
						}
						if (o[0] === 'TPS') {
							var tps = tvWidget.chart().createOrderLine().setText('Take Profit Short ' + o[2]).setLineLength(3).setLineColor('rgba(246,70,92,1)').setLineStyle(2);
							tps.setPrice(Number(o[2]));
						}
						if (o[0] === 'SLS') {
							var sls = tvWidget.chart().createOrderLine().setText('Stop Loss Short ' + o[3]).setLineLength(3).setLineColor('rgba(246,70,92,1)').setLineStyle(2);
							sls.setPrice(Number(o[3]));
						}
					}
				});
			}
		});
	}

	public componentWillUnmount(): void {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	public render(): JSX.Element {
		return (
			<div
				ref={ this.ref }
				className={ 'TVChartContainer' }
			/>
		);
	}
}
